<template lang="pug">
  ul.social
    li(
      v-for="(item, index) in social"
      :key="index"
    )
      a(
        :href="item.link"
        target="_blank"
      ).social__link
        img(
          :src="item.icon"
          alt="social"
        )
</template>

<script>
// images
import Social1 from '@/assets/icons/social-1.svg'
import Social2 from '@/assets/icons/social-2.svg'
import Social3 from '@/assets/icons/social-3.svg'
import Social4 from '@/assets/icons/social-4.svg'
export default {
  name: 'SocialBlock',
  data () {
    return {
      social: [
        {
          link: 'https://www.facebook.com/disoftdev',
          icon: Social1
        },
        {
          link: 'https://www.behance.net/disoft_us',
          icon: Social2
        },
        {
          link: 'https://www.instagram.com/disoftdev',
          icon: Social3
        },
        {
          link: 'https://www.linkedin.com/company/disoftdev',
          icon: Social4
        }
      ]
    }
  }
}
</script>
